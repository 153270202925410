<template>
  <ModalTokenDetails
    v-if="showSidebar"
    :coin="coin"
    :news-item="newsItem"
    :back-option="true"
    :social-news="socialNews"
  />
</template>
<script setup>
import ModalTokenDetails from '@/components/modal/ModalTokenDetails.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const showSidebar = computed(() => $store.getters.coinSidebar);
const coin = computed(() => $store.state.sidebarData.coin);
const newsItem = computed(() => $store.state.sidebarData.newsItem);
const socialNews = computed(() => $store.state.sidebarData.socialNews);
</script>
